import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { changeProgressBarOnScroll } from "../components/utils/index"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

export default class PMCarePage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "PMCare")
  }

  render() {
    return (
      <Layout>
        <div id="pmcare">
          <ProgressBar />
          <HeadMeta metadata={pageData.metadata} />
          <section
            className="hero hero-landing bg-gray"
            style={{
              backgroundPosition: "center",
              backgroundSize: 'cover'
            }}
          >
            <div className="container">
              <p className='mb-5 h3 d-md-none d-block text-center'>
                <img
                  src={withPrefix("img/logo-vertical.png")}
                  style={{ height: "75px" }}
                  alt="AdvanxHealth"
                />
                <i className="fa fa-times mx-4"></i>
                <img
                  src="https://www.pmcare.com.my/wp-content/uploads/2020/03/pmcare-logo-retina.png"
                  style={{ height: "75px" }}
                  alt="PMCare"
                />
              </p>
              <div className="row d-flex align-items-center">
                <div className="col-md-6">
                  <img
                    className="img-fluid"
                    src={withPrefix("img/product-box.png")}
                    alt="Product box"
                  />
                </div>
                <div className="col-md-6 text-center text-md-right order-2 order-md-1 p-4">
                  <p className='mb-5 h3 d-md-block d-none'>
                    <img
                      src={withPrefix("img/logo-vertical.png")}
                      style={{ height: "75px" }}
                      alt="AdvanxHealth"
                    />
                    <i className="fa fa-times mx-4"></i>
                    <img
                      src="https://www.pmcare.com.my/wp-content/uploads/2020/03/pmcare-logo-retina.png"
                      style={{ height: "75px" }}
                      alt="PMCare"
                    />
                  </p>
                  <img
                    className="img-fluid mb-4"
                    src={withPrefix("img/logo/DNA-Explorer-Prime-Oneline.png")}
                    style={{ width: "400px" }}
                    alt="DNA Explorer Prime"
                  />
                  <h1 className='h2 mb-5'>Discover your genetics and take control of your health</h1>
                  
                  <h4>
                    <span className='badge badge-pill px-3'>
                      EXCLUSIVE DISCOUNT
                    </span>      
                  </h4>
                  <h2>
                    RM1200
                  </h2>
                  <strike className='h4'>
                    RM1599
                  </strike>
                </div>
              </div>
            </div>
          </section>
          <section id="benefits" className="py-5">
            <div className="container">
              <div className="text-center">
                <h2 className='mb-5'>
                  PMCare Member Benefits
                </h2>
              </div>
              <div className="row">
                <div className="col-md-2 offset-md-1 col-6 my-3">
                  <img
                    src="/img/pmcare/usp-1.png"
                    className="img-fluid"
                    alt="Lifetime Consultation"
                  />
                </div>
                <div className="col-md-2 offset-md-2 col-6 my-3">
                  <img
                    src="/img/pmcare/usp-2.png"
                    className="img-fluid"
                    alt="Lifetime Digital Report Access & Update"
                  />
                </div>
                <div className="col-md-2 offset-md-2 col-6 my-3">
                  <img
                    src="/img/pmcare/usp-3.png"
                    className="img-fluid"
                    alt="Door-to-door Pickup Services"
                  />
                </div>
                <div className="col-md-2 offset-md-3 col-6 my-3">
                  <img
                    src="/img/pmcare/usp-4.png"
                    className="img-fluid"
                    alt="Reliable Results with Proven Evidence"
                  />
                </div>
                <div className="col-md-2 offset-md-2 col-6 offset-3 my-3">
                  <img
                    src="/img/pmcare/usp-5.png"
                    className="img-fluid"
                    alt="Localized Report And Recommendations"
                  />
                </div>        
              </div>
              <div className='text-center mt-5'>
                <a
                  href="https://app.advanxhealth.com/products/overview?product=34"
                  className="btn btn-pmcare"
                >
                  Get It Now
                </a>
              </div>
            </div>
          </section>
          <section id="whats-included" className="bg-white py-5">
            <div className="container text-center">
              <div className="row my-4">
                <div className="col-md-10 offset-md-1">
                  <h2 className="text-center">
                    What's Included In{" "}
                    <img
                      className="img-fluid"
                      src={withPrefix("img/logo/DNA-Explorer-Prime-Oneline.png")}
                      style={{ maxWidth: "300px" }}
                      alt="DNA Explorer Prime"
                    />
                  </h2>
                </div>
                <div className="col-md-6 offset-md-3">
                  <p className="text-center mb-5">
                    Take a step toward total health with 124 comprehensive DNA reports
                  </p>
                </div>
              </div>
              <div className="container">
                <div className="row align-items-center mb-3">
                  <div className='col-md-3'>
                    <Link to={withPrefix("/reports/health")}>
                      <img
                        src="/img/pmcare/health.png"
                        className="img-fluid mb-5"
                        alt="Health Risk"
                      />
                    </Link>
                  </div>
                  <div className='col-md-3'>
                    <Link to={withPrefix("/reports/fitness")}>
                      <img
                        src="/img/pmcare/fitness.png"
                        className="img-fluid mb-5"
                        alt="Fitness"
                      />
                    </Link>
                  </div>
                  <div className='col-md-3'>
                    <Link to={withPrefix("/reports/skin")}>
                      <img
                        src="/img/pmcare/skin.png"
                        className="img-fluid mb-5"
                        alt="Skin"
                      />
                    </Link>
                  </div>
                  <div className='col-md-3'>
                    <Link to={withPrefix("/reports/weight-management")}>
                      <img
                        src="/img/pmcare/weight-management.png"
                        className="img-fluid mb-5"
                        alt="Weight Management"
                      />
                    </Link>
                  </div>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div className='col-md-3'>
                    <Link to={withPrefix("/reports/allergy-sensitivity")}>
                      <img
                        src="/img/pmcare/allergy.png"
                        className="img-fluid mb-5"
                        alt="Allergy"
                      />
                    </Link>
                  </div>
                  <div className='col-md-3'>
                    <Link to={withPrefix("/reports/inner-potential")}>
                      <img
                        src="/img/pmcare/inner-potential.png"
                        className="img-fluid mb-5"
                        alt="Inner Potential"
                      />
                    </Link>
                  </div>
                  <div className='col-md-3'>
                    <Link to={withPrefix("/reports/nutrition")}>
                      <img
                        src="/img/pmcare/nutrigenomics.png"
                        className="img-fluid mb-5"
                        alt="Nutrigenomics"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="how-it-works py-5 bg-gray">
            <div className="container text-center">
              <h2 className="text-center mb-5">
                How To Purchase
              </h2>
              <div className="row align-items-center justify-content-between">
                <div className='col-md-3'>
                  <img
                    src="/img/pmcare/step-1.png"
                    className="img-fluid mb-5 px-2"
                    alt="Step 1"
                  />
                </div>
                <div className='col-md-3'>
                  <img
                    src="/img/pmcare/step-2.png"
                    className="img-fluid mb-5 px-2"
                    alt="Step 2"
                  />
                </div>
                <div className='col-md-3'>
                  <img
                    src="/img/pmcare/step-3.png"
                    className="img-fluid mb-5 px-2"
                    alt="Step 3"
                  />
                </div>
              </div>
              <div className="row align-items-center justify-content-between">
                <div className='col-md-3'>
                  <img
                    src="/img/pmcare/step-4.png"
                    className="img-fluid mb-5 px-2"
                    alt="Step 4"
                  />
                </div>
                <div className='col-md-3'>
                  <img
                    src="/img/pmcare/step-5.png"
                    className="img-fluid mb-5 px-2"
                    alt="Step 5"
                  />
                </div>
                <div className='col-md-3'>
                  <img
                    src="/img/pmcare/step-6.png"
                    className="img-fluid mb-5 px-2"
                    alt="Step 6"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="p-0 p-md-5" style={{backgroundColor: '#1BB1E7'}}>
            <div
              className="container py-5 my-0"
            >
              <div className="row text-white align-items-center">
                <div className="col-md-1 offset-md-2">
                  <span className="text-left">
                    <i
                      className="fa fa-quote-left mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
                <div className="col-md-6">
                  <h3 className="text-white text-center ">
                    Your DNA loads the gun,
                    <br className="" /> your lifestyle pulls the trigger.
                  </h3>
                </div>
                <div className="col-md-1 text-right text-md-left">
                  <span className="">
                    <i
                      className="fa fa-quote-right mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section id="limitation" className="bg-gray py-5">
            <div className='container'>
              <div className='row'>
                <div className='col-md-3 pr-md-5'>
                  <h2 className='text-gray'>Limitation</h2>
                </div>
                <div className='col-md-9 text-justify'>
                  <p className='lead'>
                    Genetic screening can indicate whether an individual has a predisposition, or is at increased likelihood of having an 
                    inherited disease or disorder. However, it cannot indicaate if the individual will show symptoms, how severe the symptoms 
                    will be, or whether the disease or disorder will progress over time.
                  </p>
                  <p className='lead'>
                    A negative test result does not mean that an individual is 100% free from possibilities of inheriting the disease or 
                    disorder; likewise, a positive result also does not mean an individual is 100% confirmed to develop the disorder or disease overtime.
                  </p>
                  <p className='lead'>
                    Genetic screening reports do not diagnose any health conditions or provide medical advice. The reports should not be used as 
                    a diagnostic tool; results provided are also limited to existing scientific research and will be updated upon new findings in research.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-0 bg-light" id="what-you-get py-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 order-2 order-md-1 text-center text-md-center">
                  <img
                    className="img-fluid"
                    src={withPrefix("img/logo/DNA-Explorer-Prime.png")}
                    alt="DNA Explorer Prime"
                  />
                  <h4>
                    Learn about your genetic profile
                    <br /> through 124 DNA reports
                  </h4>
                  <a
                    href="https://app.advanxhealth.com/products/overview?product=34"
                    className="btn btn-explorer-yellow mt-4 btn-lg"
                  >
                    Get It Now
                  </a>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <img
                    className="d-md-block product-gif"
                    src={withPrefix("/img/gifs/new-dashboard.gif")}
                    alt="Sample report"
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="faq" className="py-5 bg-gray">
            <h2 className='text-center mb-5'>
              Frequently Asked Questions (F.A.Q)
            </h2>
            <div className="container">
              <Accordion>
                {pageData.faqs.map((faq, i) => (
                  <AccordionItem key={i}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <h3>{faq.question}</h3>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "Exclusive Deal For PMCare Members | DNA Explorer Prime - Advanx Health",
    description:
      "Discover your risks by using the latest DNA technology from Advanx Health and enjoy special rates exclusively for PMCare members!",
    image: "https://www.advanxhealth.com/img/pmcare/meta-image.png",
    url: "https://www.advanxhealth.com/pmcare",
  },
  faqs: [
    {
      question: "What is a DNA report?",
      answer:
        "A DNA report is an interpretation of your genetic information that associates with your physical characteristics. There are two terms in genetics where we commonly use, Genotype and Phenotype."
    },
    {
      question: "What is a Genotype?",
      answer:
        "Genotype is your own heritable genetic identity, just like your finger-print (your fingerprint is unique because your DNA is unique!!).  It is your unique set of genetic information that would be revealed through DNA sequencing. However, the word genotype can also refer just to a gene or set of genes carried by an individual. For example, if you carry a mutation that is linked to obesity, your may refer to your genotype just with respect to this mutation without consideration of all the other gene variants that you may carry."
    },
    {
      question: "What is a Phenotype?",
      answer:
        "Phenotype is a visible presentation of your actual physical characteristics. This includes straightforward visible characteristics like your height and eye colour, but also your overall health, your disease risk, and even your behaviour and general disposition. Do you gain weight easily? Are you easily anxious or always calm? Do you like dogs? These are all the ways in which you present yourself to the world, and as such are considered phenotypes. However, not all phenotypes are a direct result of your genotypes; chances are that your personal disposition to dogs is the result of your life's experience with pets rather than a mutation in a hypothetical dog fancier gene."
    },
    {
      question: "How long does it take to generate a DNA report?",
      answer:
        "Due to the advancement of scientific technology, the whole process should just take about 3 - 4 weeks from sample collection to report generation."
    },
    {
      question: "How does the process works?",
      answer:
        "<ol><li>Saliva samples will be collected via the saliva sampling kit</li><li>Samples will be sent to genetic laboratory to extract DNA from the saliva</li><li>Extracted DNA will be placed under microarray machine to scan for useful genetic information</li><li>Interpretation will be done by qualified genomics experts through proprietary software & algorithms</li></ol>",
    },
    {
      question: "How accurate & reliable are the results?",
      answer:
        "If all processes are done according to recommended procedures, the results are about 99% accurate and reliable. The maturity of DNA sequencing technology has led to promising sequencing accuracy.<br/><br/>The decider in a DNA report will be the interpretation and reporting using different software and algorithm. It will depend on the level of evidence from published research research and the population data collected for a particular set of genotypes.<br/><br/>Right now, we employ the most recent genomics studies and offers the largest set of microarrays for DNA processing. In other words, we scan and process more DNA information than other competitors in the market. We also offer experienced genomic counsellor with localised expertise to help answer your doubts in your DNA report.",
    },
    {
      question: "What can I benefit from the report?",
      answer:
        "From the DNA report, you will be able to see your genetic disposition to certain physical characteristics. Through a better understanding of your genetic makeup, you will be able to make better informed decision on your life and your health."
    },
    {
      question: "Will saliva contain enough DNA?",
      answer:
        "The DNA in saliva originates from the cells that are shed from the inner linings of the mouth and from white blood cells.<br/><br/>These DNA-containing cells are collected, and the DNA is then extracted by various methods. Easy and accurate salivary DNA analysis enables the genotyping of well-characterized single nucleotide polymorphisms (SNPs) that can profoundly impact your research.<br/><br/>Research has revealed that salivary DNA is equivalent in quantity and purity to DNA obtained from blood, and that the stability of salivary DNA is good when proper methods of handling are employed."
    },
    {
      question: "Do I need to do sampling at clinics/pharmacy?",
      answer:
        "There is no need for you to travel, just stay put at your home and we will send the sampling kits to your doorstep.<br/><br/>Simply follow the steps on the saliva collection kit to complete your saliva sampling and send it back to us (free of charge)!"
    },
  ],
}
